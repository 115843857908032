export const WORKS = [
    {
        img: "images/tellme1.png",
        title: "Te(llM)e",
        type: ["Frontend Design", "Frontend Development"],
        desc: ["We build a system to respond to online surveys automatically using artificial responses. The system is capable of simulating various personas to ensure the survey can be targeted at specific user groups. In the second step, we validated our system using responses from X publicly available survey response datasets. "]
    },
    {
        img: "images/acs1.png",
        title: "ACS360",
        type: ["Frontend Design", "Frontend Development"],
        desc: [""]
    },
    {
        img: "images/ml1.png",
        title: "ML Dashboard",
        type: ["Frontend Design", "Frontend Development"],
        desc: [""]
    },
    {
        img: "images/covid1.png",
        title: "Pandemic Monitor",
        type: ["Frontend Design", "Frontend Development"],
        desc: [""]
    },
    {
        img: "images/cubes1.jpg",
        title: "Cubes",
        type: ["Tangible User Interface"],
        desc: ["A user study of physicalizing the feelings about interpersonal physical touch. By abandoning encoding and decoding, the design aims to invite the users to the creation of individual experience and create a direct and intuitive interaction."]
    },
    {
        img: "images/cycles.jpg",
        title: "Cycles",
        type: ["Shape-Shifting User Interface"],
        desc: ["A meticulously crafted luminaire that integrates the tranquil rhythms of nature into daily living. Drawing inspiration from natural phenomena such as tidal movements and seasonal transitions, its dynamic silhouette emulates a slowed human breath, emphasizing mindfulness and well-being."]
    }
];