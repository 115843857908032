import './Layout.css';
import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {AppBar, Box, Toolbar} from "@mui/material";
import {HashLink} from "react-router-hash-link";

function Layout() {
    const [sections, setSections] = useState([]);
    const [currentSection, setCurrentSection] = useState('home');
    // const [closeToSection, setCloseToSection] = useState('');

    const handleScroll = () => {
        let current = ""
        // let close = "";
        sections.forEach(section => {
            const sectionTop = section.getBoundingClientRect().top;
            // console.log(parseInt(sectionTop));
            if (parseInt(sectionTop) <= 0) {
                current = section.id;
            }
            // else if (parseInt(sectionTop) <= 90) {
            //     close = section.id;
            // }
        });
        // console.log(current, close);
        setCurrentSection(current);
        // setCloseToSection(close);
        // console.log(currentSection);
    };

    const updateNavLinks = () => {
        // const header = document.querySelector('.header');
        const navLinks = document.querySelectorAll('.nav-links a');

        navLinks.forEach(link => {
            link.classList.remove('active-section');
            link.classList.add('inactive-section');
            if (link.getAttribute('href') === "/#" + currentSection) {
                link.classList.add('active-section');
                link.classList.remove('inactive-section');
            }
        });

        // const homeSection = document.getElementById('home');
        // const workSection = document.getElementById('work');
        // const contactSection = document.getElementById('contact');
        // console.log(workSection, contactSection, header);

        // if (workSection && contactSection && header) {
            // const homeSectionTop = homeSection.getBoundingClientRect().top;
            // const workSectionTop = workSection.getBoundingClientRect().top;
            // const contactSectionTop = contactSection.getBoundingClientRect().top;
            // console.log(homeSectionTop, workSectionTop, contactSectionTop);
            // console.log(currentSection);
            // console.log(parseInt(contactSectionTop));
            // if (currentSection === "contact" || currentSection === "home" || currentSection === "work") {
            //     header.classList.add('header-light');
            //     header.classList.remove('header-dark');
            // } else {
            //     header.classList.add('header-dark');
            //     header.classList.remove('header-light');
            // }
        // }
    }

    useEffect(() => {
        setSections(document.querySelectorAll('.section'));
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // window.addEventListener('scroll', updateNavLinks);
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };

        const hash = window.location.hash;
        if (hash) {
            const targetSection = document.querySelector(hash);
            if (targetSection) {
                targetSection.scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [sections]);

    useEffect(() => {
        updateNavLinks();
    }, [currentSection, sections]);

    return (
        <>
            <div className={"app"}>
                <Box className={"header header-light"} sx={{display: 'flex'}}>
                    <AppBar position="static">
                        <Toolbar>
                            <div className={"header-div flex_row"}>
                            <HashLink to="/#home" className={"flex1 flex-column logo"}>
                                <div>Ziyang Zhou</div>
                            </HashLink>
                            <div className={"nav-links"}>
                                <HashLink to="/#home" className={"active-section"}>Home</HashLink>
                                <HashLink to="/#work" className={"inactive-section"}>Work</HashLink>
                                <HashLink to="/#contact" className={"inactive-section"}>Contact</HashLink>
                            </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box component="main" sx={{p: 3}} className={"homepage-container"}>
                    <Outlet/>
                </Box>
            </div>
            {/*<div className="full-screen-message">This application is best viewed on a larger screen. The mobile version*/}
            {/*    is coming soon.*/}
            {/*</div>*/}
        </>
    );
}

export default Layout;