import React, {useEffect} from 'react';
import "../../style.css";
import "./Home.css";
import {HashLink} from "react-router-hash-link";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import WorkSection from "../../components/WorkSection/WorkSection";
import Button from "@mui/material/Button";
import FullScreenDialog from "../../components/WorkView/WorkView";
import {WORKS} from "../../config";

function Home() {
    useEffect(() => {
        const handleScroll = () => {
            const homeSection = document.querySelector('.home');
            const homeHeadline = document.querySelector('.home-headline');
            const homeRole = document.querySelector('.home-role');
            const fontLight = document.querySelectorAll('.home-headline span.font-light');
            const scrollPosition = window.scrollY;
            const homeSectionHeight = homeSection.offsetHeight;
            const keyframes_ratio = [0.25, 0.5, 1];
            const keyframes = keyframes_ratio.map(ratio => homeSectionHeight * ratio);

            if (scrollPosition <= keyframes[0]) {
                homeSection.style.opacity = 1;
                // homeRole.style.opacity = 1;
                const width = 100 - Math.min((scrollPosition / keyframes[0]), 1) * 100 + '%';
                document.documentElement.style.setProperty('--stripe-width', width);
                // fontLight.forEach(light => {
                //     light.style.color = 'rgba(255, 255, 255, 1)';
                // })
            } else if (scrollPosition > keyframes[0] && scrollPosition <= keyframes[1]) {
                homeSection.style.opacity = 1;
                // homeHeadline.style.opacity = 1;
                document.documentElement.style.setProperty('--stripe-width', '0%');
                // fontLight.forEach(light => {
                //     light.style.color = 'rgba(255, 255, 255, ' + (1 - ((scrollPosition - keyframes[0]) / (keyframes[1] - keyframes[0])) + ')');
                // })
            } else if (scrollPosition > keyframes[1] && scrollPosition <= keyframes[2]) {
                homeSection.style.opacity = 1 - ((scrollPosition - keyframes[1]) / (keyframes[2] - keyframes[1])) * 1.8;
                // homeHeadline.style.opacity = 1 - ((scrollPosition - keyframes[1]) / (keyframes[2] - keyframes[1]));
                document.documentElement.style.setProperty('--stripe-width', '0%');
                // fontLight.forEach(light => {
                //     light.style.color = 'rgba(255, 255, 255, 0)');
                // })
            } else {
                homeSection.style.opacity = 0;
                // homeHeadline.style.opacity = 0;
                document.documentElement.style.setProperty('--stripe-width', '0%');
                // fontLight.forEach(light => {
                //     light.style.color = 'rgba(255, 255, 255, 0)';
                // })
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const [open, setOpen] = React.useState(false);
    //
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    return (<>
        <div className="home section" id={"home"}>
            <div className="page-container home-container flex_col">
                <video autoPlay muted loop id="myVideo" poster={"images/bg_video_1.jpg"}>
                    <source src="bg_video_1.mp4" type="video/mp4"/>
                </video>
                <div className="home-role">
                    Developer / Designer / Human-Centred Problem Solver
                </div>
                <div className="home-headline page">
                    <span className="font-medium selected" data-text={"unveiling"}>unveiling</span>
                    <span className="font-medium"> the power of </span>
                    <span className="font-medium"> digital experience through cutting-edge </span>
                    <span className="font-light">technology</span>
                    <span className="font-medium"> and </span>
                    <span className="font-light">creativity</span>
                    <span className="font-medium">.</span>
                </div>
            </div>
            <div className={"next-page"}>
                <div className={"next-page-button"}>
                    <HashLink to={"/#work"} className={"next-page-text"}>
                        <KeyboardArrowDownSharpIcon fontSize="inherit"/>
                    </HashLink>
                </div>
            </div>
        </div>
        <div className="work section" id={"work"}>
            <div className={"page-container work-container flex_col"}>
                {WORKS.map((work, index) => {
                    return (
                        <WorkSection className={"work-item"} key={index} img={work.img} title={work.title} type={work.type} desc={work.desc}/>
                    )
                })}
            </div>
        </div>
        <div className="contact section" id={"contact"}>
            <div className={"page-container contact-container flex_row"}>
                <div className={"flex1 flex_col"}>
                    <div className={"flex0 flex_col page-left contact-left"}>
                        <div className={"page-title contact-title flex0"}>Contact</div>
                        <div className={"page-intro contact-intro flex0 flex_col"}>
                            <span>I'm currently open for opportunities.</span>
                        </div>
                    </div>
                </div>
                <div className={"flex2 page-content work-content flex_row"}>
                </div>
            </div>
        </div>
        {/*<FullScreenDialog open={open} setOpen={setOpen}/>*/}
    </>);
}

export default Home;