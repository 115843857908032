import React from 'react';
import "../../style.css";
import "./WorkSection.css";
import CardMedia from '@mui/material/CardMedia';

function WorkSection(props) {
    return (
        <div className={"work-row " + props.className}>
            <div className={"flex0 flex_col work-left"}>
                <div className={"work-title flex0"}>{props.title}</div>
                <div className={"work-desc flex1 flex_row"}>
                    <div className={"work-type-text flex1 flex_col"}>
                        {props.type.map((item, index) => {
                            return (
                                <div key={index} className={"work-type-line"}>{item}</div>
                            )
                        })}
                    </div>
                    <div className={"work-desc-text flex1 flex_col"}>
                        <div className={"work-desc-segment"}>
                            {props.desc.map((item, index) => {
                                return (
                                    <div key={index} className={"work-desc-line"}>{item}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/*<div className={"page-intro work-intro flex0 flex_col"}>*/}
                {/*    <span>This is a showcase of my work in a variety of fields including Front-End Design & Development, Prototyping, Back-End Development, Tangible User Interface.</span>*/}
                {/*    /!*<span>The world of digital design and development is constantly evolving and so has my role over the last 15 years. I’m still learning and gaining new skills every day.</span>*!/*/}
                {/*</div>*/}
            </div>
            <div className={"flex1 page-content work-content flex_col"}>
                <div className={"work-item flex1"}>
                    <CardMedia
                        component="img"
                        alt={props.title}
                        image={props.img}
                    />
                    {/*<Card sx={{}} className={"item-card"}>*/}
                    {/*    <CardMedia*/}
                    {/*        component="img"*/}
                    {/*        alt={props.title}*/}
                    {/*        image={props.img}*/}
                    {/*        // sx={{objectFit: "cover"}}*/}
                    {/*    />*/}
                    {/*    <CardContent className={"card-item-text"}>*/}
                    {/*        /!*<Typography gutterBottom variant="h3" component="div">*!/*/}
                    {/*        /!*    {props.title}*!/*/}
                    {/*        /!*</Typography>*!/*/}
                    {/*        <Typography variant="body1" sx={{color: 'text.secondary'}}>*/}
                    {/*            {props.desc}*/}
                    {/*        </Typography>*/}
                    {/*    </CardContent>*/}
                    {/*</Card>*/}
                </div>
            </div>
        </div>
    )
        ;
}

export default WorkSection;